<!-- System: STA
    Purpose: This compoment will create/display
            single images
-->
<template>
  <div
    class="img-placholder text-center intellegence-report-img work-img-padding"
  >
    <!-- <div class="cam-placeholder" :style="{'background-image' : 'url(' + url() +
              'storage/screenshots/thumbnails/' +
              image.screenshot_token +
              '/' +
              $route.params.id +
              '/' +
              image.project_id +
              '/' +
              selectedMember +
              '/' +
              image.filename + ')' }"> -->
    <div class="cam-placeholder">
      <div class="position-relative" v-if="user_allowed"></div>
      <!-- <img id="ss-img" class="d-none"   @error="showAlert()" src="" alt="no image"> -->
      <!-- {{url() + 'storage/screenshots/full_size/' + image.screenshot_token + '/' + $route.params.id + '/' + image.project_id + '/' +  selectedMember + '/' + image.filename}} -->
      <a
        id="screenshot"
        @click="
          checkUrl(
            url() +
              'storage/screenshots/full_size/' +
              image.screenshot_token +
              '/' +
              $route.params.id +
              '/' +
              image.project_id +
              '/' +
              selectedMember +
              '/' +
              image.filename
          )
        "
        :data-title="image.capture_time + '\n' + image_info"
        :href="
          url() +
          'storage/screenshots/full_size/' +
          image.screenshot_token +
          '/' +
          $route.params.id +
          '/' +
          image.project_id +
          '/' +
          selectedMember +
          '/' +
          image.filename
        "
        data-disableScrolling="true"
        data-lightbox="image-1"
        v-if="image.filename.length < 60"
      >
        <div
          @mouseleave="leaveSpan"
          @mouseover="toggleSpanGrand(image.id)"
          :style="{ 'background-position': image.css }"
          class="screenshot mx-auto"
          :class="mouseOverImage == image.id ? 'imageBlur' : ''"
        ></div>
      </a>

      <a
        id="screenshot"
        @click="
          checkUrl(
            url() +
              'storage/screenshots/full_size/' +
              image.screenshot_token +
              '/' +
              $route.params.id +
              '/' +
              image.project_id +
              '/' +
              selectedMember +
              '/' +
              image.filename
          )
        "
        :data-title="image.capture_time + '\n' + image_info"
        :href="
          url() +
          'storage/screenshots/full_size/' +
          image.screenshot_token +
          '/' +
          image.filename
        "
        data-disableScrolling="true"
        data-lightbox="image-1"
        v-else
      >
        <div
          @mouseleave="leaveSpan"
          @mouseover="toggleSpanGrand(image.id)"
          :style="{ 'background-position': image.css }"
          :class="mouseOverImage == image.id ? 'imageBlur' : ''"
          class="screenshot"
        ></div>
      </a>

      <v-dialog v-model="del_img_dialog" max-width="450">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :id="image.id"
            v-bind="attrs"
            v-on="on"
            @mouseleave="leaveSpan"
            @mouseover="toggleSpanGrand(image.id)"
            :class="mouseOverImage == image.id ? 'image-show' : 'image-hover'"
            class="fa fa-trash float-right mt-1 hand text-white"
            v-if="user_allowed && hideDelete === true"
          >
            mdi-trash-can
          </v-icon>
        </template>
        <v-card>
          <v-toolbar color="primary" class="headline" dark
            >Are you sure?</v-toolbar
          >
          <v-card-text class="pa-2"
            >You want to delete this Screen Shot. If you delete the screen shot,
            your time will be deducted from time log.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="del_img_dialog = false">
              cancel
            </v-btn>
            <v-btn color="red darken-1" text @click="removeImage(image)">
              ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-menu
      v-model="menu"
      open-on-hover
      :close-on-content-click="false"
      :nudge-width="100"
      bottom
      offset-y
      rounded="lg"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-progress-linear
          :value="image.progress"
          color="#4acf8b"
          height="5"
          v-bind="attrs"
          v-on="on"
          class="custom-progress-width mt-n2"
        ></v-progress-linear>
        <p class="float-left custom-margin-top">
          {{ timeConvert(image.capture_time) }}
        </p>
      </template>
      <v-card>
        <v-card-text>
          <div class="project-info-grid">
            <div class="small text-left mb-1">Project:</div>
            <div class="small text-left pl-1">{{ project_info }}</div>
            <div class="small text-left">Task:</div>
            <div class="small text-left pl-1">{{ task_info }}</div>
          </div>
          <!--        </v-card-text>-->
          <!--        <v-divider></v-divider>-->
          <!--        <v-card-text>-->
          <div
            class="activity text-center"
            v-if="
              selectedCompanyUserStatus !== 'employee' &&
              !(100 - image.progress == 100)
            "
          >
            <p class="font pt-0 mb-2 events-per-interval">
              Events per Interval
            </p>
            <div class="strokes-grid">
              <div class="font-weight-bolder small">Idle</div>
              <div class="font-weight-bolder small">Clicks</div>
              <div class="font-weight-bolder small">Strokes</div>
              <div class="small">{{ 100 - image.progress }}%</div>
              <div class="small">{{ image.mouse_clicks }}</div>
              <div class="small">{{ image.key_strokes }}</div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { baseURL } from "@/assets/js/urls";
require("lightbox2/dist/js/lightbox.min");
import "lightbox2/dist/css/lightbox.min.css";

export default {
  data() {
    window.location.pathname;
    return {
      menu: false,
      checkedNames: [],
      hideDelete: true,
      member_id: "",
      mouseOverImage: null,
      del_img_dialog: false,
    };
  },
  name: "work-diary-single-image",
  props: ["image", "selectedMember"],
  computed: {
    ...mapState("custom", ["selected_company", "companies_list"]),
    ...mapState("auth", ["user"]),
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    image_info() {
      return (
        "Project: " + this.image.project + "\n" + " Task: " + this.image.task
      );
    },
    project_info() {
      return this.image.project;
    },
    task_info() {
      return this.image.task;
    },
    user_allowed() {
      return this.selectedMember === this.user.id;
    },
  },
  methods: {
    checkUrl() {
      var screenshot = document.getElementById("screenshot");
      var link = screenshot.getAttribute("href");
      this.createImgElement(link);
    },
    createImgElement(link) {
      setTimeout(
        function () {
          var img = document.createElement("img");
          img.src = link;
          img.addEventListener(
            "error",
            function (event) {
              var myRequest = new Request(event.path[0].currentSrc);
              fetch(myRequest).then(
                function (response) {
                  if (response.status == 501) {
                    this.showAlert();
                  }
                }.bind(this)
              );
            }.bind(this)
          );
        }.bind(this),
        1000
      );
    },
    showAlert() {
      this.$root.$emit("snack_bar", {
        show: true,
        message: "Image link Expired, generating new link",
        snackbarColor: "blue",
      });
      setTimeout(
        function () {
          document.getElementById("search-workdiary-btn").click();
          document.getElementById("lightbox").click();
        }.bind(this),
        4000
      );
    },
    timeConvert(date) {
      let timeString = date;
      let H = +timeString.substr(0, 2);
      let h = H % 12 || 12;
      let s = timeString.substr(6, 8);
      let ampm = H < 12 ? " AM" : " PM";
      return h + timeString.substr(2, 3) + ":" + s + ampm;
    },

    toggleSpanGrand(id) {
      this.mouseOverImage = id;
    },
    leaveSpan() {
      this.mouseOverImage = null;
    },
    url() {
      return baseURL.API_URL;
    },

    removeImage: function (image) {
      // console.log("deleteeee");
      // return;
      let data = {
        screenshot_id: image.id,
        company_id: this.$route.params.id,
        member_id: this.selectedMember,
        project_id: image.project_id,
      };
      this.$store
        .dispatch("custom/remove_screenshoot", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);

          if (response.data.error === false) {
            this.$store.commit("custom/delete_screenshot", image);
            this.$emit("imageDelete", image.id);
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
          }
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          if (error.response.data.error === true) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.error_msg,
              snackbarColor: "red",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.v-progress-linear.custom-progress-width.theme--light {
  width: 90%;
  background-color: #cbdaf2 !important;
  /* margin-left: 17px; */
  margin: 0 auto;
  border-radius: 4px;
}
p.float-left.custom-margin-top {
  font-size: 15px;
  color: #36454f;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
}
.events-per-interval {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}
.font-weight-bolder {
  font-size: 12px;
}
.activity {
  box-sizing: border-box;
  height: 81px;
  /*width: 197px;*/
  border: 1px solid #cbdaf2;
  border-radius: 4px;
  background-color: #ffffff;
}
.strokes-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  color: #36454f;
}

.project-info-grid {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 5px;
  width: 180px;
  /*color: #ffffff;*/
  font-size: 12px;
  color: #242e4c;
  letter-spacing: 1px;
  line-height: 12px;
  height: auto !important;
  padding-top: 8px;
}

.img-placholder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*background-color: #ecf3ff !important;*/
}

.bottom-bar-time {
  color: #36454f;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 21px;
  border-radius: 3px !important;
  padding: 1px 3px;
  overflow: hidden;
  margin-top: 4px !important;
}

p.custom-margin-top.float-left.mb-0 {
  margin-top: 3px;
}

.cam-placeholder {
  /*padding: 25px 0px 25px 0px;*/
  height: 72px;
  /* padding: 5px; */
  /*border: 1px solid #d9e0f7;*/
  background-color: #fbfcff;
  position: relative;
  border-radius: 8px;
}

.cam-placeholder .fa-camera {
  padding: 25px 0px 25px 0px;
}

.delete-check {
  position: absolute;
  left: 2px;
  /*display: none;*/
}

.info-icon {
  position: absolute;
  right: 2px;
  background: #fff;
}

.img-placholder:hover .delete-check {
  display: block;
}

.no_image {
  max-height: 45px;
  margin-top: 8px;
}

.v-icon.fa-trash {
  color: #204ee0 !important;
}

.font {
  color: #36454f;
  font-family: Poppins, sans-serif;
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 12px;
  font-weight: bold;
  padding: 5px 0px 0px 10px !important;
}

.Border {
  border-radius: 3px;
}
.imageBlur {
  opacity: 0.4;
}
.v-icon.fa-trash {
  position: absolute;
  color: #204ee0 !important;
  top: 20px;
  right: 41px;
  opacity: 1;
}
.image-hover {
  display: none;
}
.image-show {
  visibility: visible;
}
.image-hide {
  visibility: none;
}
@media (max-width: 1200px) {
  .v-progress-linear.custom-progress-width.theme--light {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .v-progress-linear.custom-progress-width.theme--light {
    margin-left: 0px;
  }
}
</style>
